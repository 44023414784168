<template>
  <div class="layout_nav">
    <div class="page_width nav tw-flex tw-items-end">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.layout_nav {
  width: 100%;
  height: 50px;
  background: linear-gradient(90deg, #31394C 0%, #1A1D27 100%);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
}
.nav {
  height: 100%;
  padding: 0 20px;
}
</style>
