<template>
  <div class="nav_item text_size_14 tw-flex tw-justify-center tw-items-center" :class="{'active': isActive}" @click="onClick">
    <p class="nav_item_label">{{item.label}}</p>
  </div>
</template>

<script>
export default {
  props: {
    item: Object
  },
  computed: {
    isActive () {
      return this.$route.meta.nav === this.item.nav
    }
  },
  methods: {
    onClick () {
      this.$router.push(this.item.path)
    }
  }
}
</script>

<style lang="scss" scoped>
.nav_item {
  cursor: pointer;
  min-width: 100px;
  height: 46px;
  margin-right: 6px;
  background-color: rgba($color: #424857, $alpha: 0);
  color: rgba($color: #ffffff, $alpha: 0.8);
  &:hover, &.active {
    font-weight: bold;
    background-color: #424857;
  }
}
</style>
