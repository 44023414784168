<template>
  <div class="layout">
    <layout-header />
    <layout-nav>
      <layout-nav-item
        v-for="(navItem, index) in navItemList"
        :key="index"
        :item="navItem">
      </layout-nav-item>
    </layout-nav>
    <div class="layout_main">
      <router-view />
    </div>
  </div>
</template>

<script>
import layoutHeader from './layout-header'
import layoutNav from './layout-nav'
import layoutNavItem from './layout-nav-item'
import globalVar from '@/configs/globalVar'
export default {
  components: {
    layoutHeader,
    layoutNav,
    layoutNavItem
  },
  data () {
    return {
    }
  },
  computed: {
    navItemList () {
      const superAdmin = globalVar.ADMIN_ROLE_0
      const admin = globalVar.ADMIN_ROLE_1
      const helper = globalVar.ADMIN_ROLE_2
      const list = [
        { path: '/index', label: '首页', nav: 'index', roles: [superAdmin, admin] },
        { path: '/account-manage/user-list', label: '账号管理', nav: 'account-manage', roles: [superAdmin, admin, helper] },
        { path: '/resource-require', label: '服务/需求/成果', nav: 'resource-require', roles: [superAdmin, admin, helper] },
        { path: '/service-chain-manage', label: '服务链管理', nav: 'service-chain-manage', roles: [superAdmin, admin] },
        { path: '/order-manage', label: '订单管理', nav: 'order-manage', roles: [superAdmin, admin] },
        { path: '/order-feedback', label: '订单反馈', nav: 'order-feedback', roles: [superAdmin, admin] },
        { path: '/info-manage', label: '资讯管理', nav: 'info-manage', roles: [superAdmin, admin] }
      ]
      return list.filter(item => item.roles.includes(this.role))
    }
  }
}
</script>

<style lang="scss" scoped>
.layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #F2F2F2;
  &_main {
    flex: 1;
    overflow: auto;

  }
}
</style>
