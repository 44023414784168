<template>
  <div class="layout_header">
    <div class="page_width header tw-flex tw-justify-between tw-items-center">
      <div class="header_left tw-flex tw-items-center">
        <img class="header_left_logo" src="../../assets/images/logo.png" alt="">
        <div class="header_left_title tw-flex tw-items-center">
          <p class="header_left_title-main text_size_18 tw-font-bold">共创云</p>
          <p class="header_left_title-sub text_size_14">后台管理系统</p>
        </div>
      </div>
      <div class="header_right tw-flex tw-items-center">
        <el-popover
          class="avatar_popover"
          placement="bottom-end"
          trigger="click"
          :visible-arrow="false"
          :offset="30"
          width="150">
          <div class="avatar_popover_content">
            <div class="content_bottom">
              <div class="handle_item" @click="logout">
                <i class="iconfont icontuichudenglu tw-mr-20px "></i>
                <h1 class="text_size_14">退出登录</h1>
              </div>
            </div>
          </div>
          <div slot="reference" class="avatar_popover_reference">
            <user-avatar url="" />
          </div>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
import userAvatar from '@/components/user-avatar'
import cookie from '@/configs/cookie'

export default {
  components: {
    userAvatar
  },
  mounted () {
    this.$store.dispatch('getUserInfo')
  },
  methods: {
    // 退出
    logout () {
      cookie.remove(this.globalVar.CMS_TOKEN)
      this.$router.replace('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
.layout_header {
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.06);
}
.header {
  height: 100%;
  &_left {
    &_logo {
      margin-right: 10px;
    }
    &_title {
      &-main {
        position: relative;
        line-height: 25px;
        color: #000000;
        margin-right: 21px;
        &::after {
          content: "";
          position: absolute;
          width: 1px;
          height: 20px;
          right: -10px;
          top: 50%;
          transform: translateY(-50%);
          background-color: #DCDFE6;
        }
      }
      &-sub {
        line-height: 20px;
        color: #606266;
      }
    }
  }
  .header_right {
    .avatar_popover {
      .avatar_popover_reference {
        display: flex;
        align-items: center;
        height: 32px;
        cursor: pointer;
      }
    }
  }
}

.avatar_popover_content {
  .content_bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60px;
    padding: 10px 0;
    .handle_item {
      display: flex;
      align-items: center;
      padding-left: 20px;
      height: 40px;
      transition: all 0.3s ease;
      cursor: pointer;
      &:hover {
        background-color: $--color-primary-light-9;
      }
    }
  }
}
</style>
